import {Injectable} from '@angular/core';
import {Actions, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {newContextAction} from '../../auth/auth-store/auth.actions';
import {LocalStorageService} from './local-storage.service';

export const filterSettingsKeys = {
  assignment: 'assignmentFilter',
  assignmentFinished: 'assignmentFinishedFilter',
  wagonsOrigin: 'filterWagonsOrigin',
  wagonsDestination: 'filterWagonsDestination',
  orderReservation: 'orderReservationFilter2',
  orderList: 'orderListFilter',
  orderListFinished: 'orderListFinishedFilter',
  emptyWagonCurrent: 'emptyWagonCurrent',
  emptyWagonFinished: 'emptyWagonFinished',
  storage: 'storageFilter',
  orderTemplates: 'orderTemplatesFilter'
} as const;
@Injectable({
  providedIn: 'root'
})
export class PersistFilterSettingsService {
  constructor(
    private store: Store,
    private localStorageService: LocalStorageService,
    private actions$: Actions
  ) {}

  /**
   * @param name local storage key that should be used to store this filter settings
   * @param filterSettings filter settings to be stored in local storage
   * @param loadedSettingsToActionFn is invoked with the loaded filter settings and returns the action that needs to be dispatched
   * @param emptySettingsAction will be dispatched when no settings are in local storage and on clearOn action.
   */
  public setupPersistentFilter<T>(
    name: (typeof filterSettingsKeys)[keyof typeof filterSettingsKeys],
    filterSettings: Observable<T>,
    loadedSettingsToActionFn: (T) => Action,
    emptySettingsAction: Action
  ) {
    const filterFromStorage = this.localStorageService.getItem(name);
    if (filterFromStorage && filterFromStorage !== 'undefined') {
      this.store.dispatch(loadedSettingsToActionFn(JSON.parse(filterFromStorage)));
    } else {
      this.store.dispatch(emptySettingsAction);
    }
    filterSettings
      .pipe(
        debounceTime(1000) // delay persistence of settings to speed up immediate update of results in UI
      )
      .subscribe(filter => this.localStorageService.setItem(name, JSON.stringify(filter)));
    this.actions$.pipe(ofType(newContextAction)).subscribe(() => this.store.dispatch(emptySettingsAction));
  }
}
